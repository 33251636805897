import { DeckMaterialCount } from '~/features/learning/deck/deck-material-count';
import { ProgressBar } from '~/features/learning/progress-bar';
import { ProgressPill } from '~/features/learning/progress-pill';
import { cn } from '~/utils/cn';

interface DeckCardProps {
  id: number | undefined;
  title: string;
  progress: {
    correct: number;
    incorrect: number;
    total: number;
  };
  questions: number;
  decks: number;
  variant?: 'learn' | 'review';
}

const FALLBACK_BG_DECK_ID = '24132129';

export function DeckCard({
  id,
  title,
  progress,
  questions,
  decks,
  variant = 'learn',
}: DeckCardProps) {
  return (
    <span className="block bg-card rounded-2xl p-4 relative">
      <span className="flex flex-col gap-3 relative z-10 pt-[60px] h-full justify-end">
        <span className="font-semibold">{title}</span>
        <ProgressBar
          {...progress}
          correctClassName={cn({
            'bg-blue-400': variant === 'review',
          })}
        />
        <span className="flex items-center justify-between gap-2">
          <DeckMaterialCount decks={decks} questions={questions} />
          {variant === 'learn' && (
            <ProgressPill
              correct={progress.correct}
              incorrect={progress.incorrect}
            />
          )}
        </span>
      </span>
      <span className="rounded-tl-2xl rounded-tr-2xl absolute h-[100px] left-1 right-1 top-1 overflow-hidden z-0">
        <img
          src={`/img/decks/${id ?? FALLBACK_BG_DECK_ID}.jpg`}
          alt={title}
          className="w-full h-full object-cover"
        />
        <span className="absolute inset-0 bg-gradient-to-b from-card/50 to-card" />
      </span>
    </span>
  );
}
