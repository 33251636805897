import { CircleCheckBigIcon, RectangleHorizontalIcon } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '~/components/tooltip';
import { cn } from '~/utils/cn';

interface DeckMaterialCountProps {
  questions: number;
  decks: number;
  className?: string;
  iconSize?: number;
}

export function DeckMaterialCount({
  decks,
  questions,
  className,
  iconSize = 14,
}: DeckMaterialCountProps) {
  return (
    <span className={cn('flex items-center gap-2', className)}>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="flex items-center justify-center gap-1 text-sm font-semibold">
              <CircleCheckBigIcon size={iconSize} />
              <span>{questions}</span>
            </span>
          </TooltipTrigger>
          <TooltipContent>Questions</TooltipContent>
        </Tooltip>
      </TooltipProvider>
      {decks > 0 && (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <span className="flex items-center justify-center gap-1 text-sm font-semibold">
                <RectangleHorizontalIcon size={iconSize} />
                <span>{decks}</span>
              </span>
            </TooltipTrigger>
            <TooltipContent>Decks</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
    </span>
  );
}
